import React from 'react';
import './consoleTableItem.css';
import WindDirectionArrow from '../common/windDirectionArrow';
import CameraIcon from '../common/sensorIcons/cameraIcon';
import CompassIcon from '../common/sensorIcons/compassIcon';
import SailSpeedSensorIcon from '../common/sensorIcons/sailSpeedSensorIcon';
import FantailIcon from '../common/sensorIcons/fantailIcon';

class ConsoleTableItem extends React.Component {

    componentDidMount() {
    }

    render() {

        var molen = this.props.molen;
        var noWind = (molen.latestWeatherObservation.windDirection == null);

        var isSelected = (this.props.selectedSmartmolen !== null && this.props.selectedSmartmolen.name === molen.name)

        return (
            <>

                <div className="console-table-item-container" style={{backgroundColor: isSelected ? "#B3C8D8" : "#E0E0E0" }}>
                    <div className="console-table-item-col1">
                        <div className="console-table-item-title">
                            {molen.name}
                        </div>
                        <div className="console-table-item-subtitle">
                            {molen.county}<br />
                        </div>
                        <div className="console-table-sensor-icons">
                            {(molen.webcam != null) && <><div className="molen-list-item-webcam-tag">WEBCAM</div><div className="console-table-sensor-icon"><CameraIcon size={16 * document.getElementsByClassName("digital-windmill-app")[0].style.getPropertyValue('--fixed-size-item-scale') } /></div></>}
                            {(molen.latestOrientationSensorReading != null) && <div className="console-table-sensor-icon"><CompassIcon size={16 * document.getElementsByClassName("digital-windmill-app")[0].style.getPropertyValue('--fixed-size-item-scale') } /></div>}
                            {(molen.latestSailRotationReading != null) && <div className="console-table-sensor-icon"><SailSpeedSensorIcon size={16 * document.getElementsByClassName("digital-windmill-app")[0].style.getPropertyValue('--fixed-size-item-scale') } /></div>}
                            {(molen.latestFantailSensorReading != null) && <div className="console-table-sensor-icon"><FantailIcon size={16 * document.getElementsByClassName("digital-windmill-app")[0].style.getPropertyValue('--fixed-size-item-scale') } /></div>}
                        </div>

                    </div>
                    <div className="console-table-item-col2">
                        {(molen.latestOrientationSensorReading != null && molen.latestOrientationSensorReading.status === "OK") &&
                            <>
                                Sails Facing<br />
                                {!(molen.isCapInFixedPosition) && <>
                                <div className="console-headline-stat">{molen.latestOrientationSensorReading.compassPoint}</div>
                                <div className="console-table-item-subtitle">{molen.latestOrientationSensorReading.bearing}&deg;</div>
                                </>
                                }
                                {(molen.isCapInFixedPosition) && <>
                                <div className="console-headline-stat">{molen.latestOrientationSensorReading.compassPoint}</div>
                                <div className="console-table-item-subtitle-red">Not turning to wind</div>
                                </>
                                }
                            </>
                        }
                    </div>
                    <div className="console-table-item-col3">
                        {molen.latestWeatherObservation && <>
                            <div className="console-table-wind">
                                <div className="console-table-wind-left">
                                    Wind<br />
                                    <div className="console-headline-stat">{(noWind) ? "None" : this.props.molen.latestWeatherObservation.windDirectionCompassPoint}</div>
                                    <div className="console-table-item-subtitle">{Math.round(molen.latestWeatherObservation.windSpeedMs * 2.24)} mph</div>
                                </div>
                                <div className="console-table-wind-right">
                                    {(!noWind) && <WindDirectionArrow windDirection={molen.latestWeatherObservation.windDirection} windingRAGStatus={molen.windingRAGStatus} windSpeedMs={molen.latestWeatherObservation.windSpeedMs} size={48 * document.getElementsByClassName("digital-windmill-app")[0].style.getPropertyValue('--fixed-size-item-scale') } palette="dark" />}
                                </div>
                            </div>
                        </>
                        }
                    </div>
                    <div className="console-table-item-right-border">
                    </div>

                </div>

            </>
        )
    }
}



export default ConsoleTableItem